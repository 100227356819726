<template>
  <v-row :class="{ expanded: expanded }">
    <v-col cols="5">
      {{ value.firstname }}
    </v-col>

    <v-col cols="5">
      {{ value.lastname }}
    </v-col>
    <v-col cols="1"> </v-col>

    <v-col cols="1" @click="expanded = !expanded" class="expandable text-right">
      <v-icon v-show="expanded">mdi-chevron-up</v-icon>
      <v-icon v-show="!expanded">mdi-chevron-down</v-icon>
    </v-col>

    <v-expand-transition>
      <v-col cols="12" v-show="expanded" class="expanded-content">
        <div v-if="loadData">
          <targets-tables v-model="value.id" />
        </div>
      </v-col>
    </v-expand-transition>
  </v-row>
</template>

<script>
import targetsTables from '@/components/feedbacks/targets/targets-tables.vue';
export default {
  props: {
    value: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      expanded: false,
      loadData: false
    };
  },

  watch: {
    expanded() {
      this.loadData = true;
    }
  },

  components: { targetsTables }
};
</script>

<style lang="scss" scoped>
.overview-body .row .expandable:hover {
  cursor: pointer;
}

.overview-body .row.table-row-divider:hover {
  background: none;
}

.table .row.table-row-divider {
  margin-top: 0;
}

.table .row.table-row-divider > div {
  padding-left: 0;
  padding-right: 0;
  padding: 0;
}

.expanded-content {
  background-color: var(--element-background-secondary);
  border-bottom: 16px solid var(--v-background-base);

  .row {
    align-items: center;
  }

  .row:after {
    display: block;
    content: ' ';
    width: 100%;
    margin-left: 1em;
    margin-right: 1em;
    border-bottom: 2px dotted rgba(0, 0, 0, 0.1);
  }

  .row:last-of-type:after {
    border-bottom: none;
  }
}

.row.expanded {
  border-top: 16px solid var(--v-background-base);
}

.row:first-of-type {
  border-top: none !important;
}

.row.expanded:after {
  display: none;
}
</style>
