<template>
  <div>
    <v-card flat class="segmented">
      <v-card-title>
        <h5>
          {{ $t('targets.targetsAsDonor') }}
        </h5>
      </v-card-title>

      <v-card-text>
        <targets-employees-table
          v-model="employees"
          :loading="employeesLoading"
          readOnly
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import targetsEmployeesTable from '@/components/feedbacks/targets/targets-employees-table.vue';
import { feedbackEnums } from '@/enums/feedbacks.js';

export default {
  data() {
    return {
      employeesLoading: true,
      tabIndex: null,
      employees: []
    };
  },

  computed: {
    ...mapGetters({
      currentUser: 'currentUser'
    })
  },

  async mounted() {
    await this.fetchUsers();
    this.employeesLoading = false;
  },

  methods: {
    ...mapActions({
      fetchAllFeedbacks: 'feedbacks/listAllFeedbacks'
    }),

    async fetchUsers() {
      const feedbacks = await this.fetchAllFeedbacks();

      var allUsers = feedbacks
        .filter((x) => x.processType === feedbackEnums.processTypes.MAFEG)
        .map((x) => {
          return {
            id: x.recipientId,
            firstname: x.recipientFirstName,
            lastname: x.recipientLastName
          };
        })
        .sort(
          (a, b) =>
            a.lastname.localeCompare(b.lastname) ||
            a.firstname.localeCompare(b.firstname)
        );

      this.employees = allUsers;
    }
  },

  components: {
    targetsEmployeesTable
  }
};
</script>
