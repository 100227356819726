<template>
  <div>
    <br class="d-none d-sm-block" />

    <v-row>
      <v-col cols="12" md="6" offset-md="6">
        <v-text-field
          v-model="keyword"
          solo
          flat
          :label="$t('searchKeywordPlaceholder')"
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
    </v-row>

    <div class="table">
      <div class="table-header">
        <v-row class="d-none d-md-flex">
          <v-col cols="5" class="caption">
            {{ $t('employeeDetails.firstName') }}
          </v-col>

          <v-col cols="5" class="caption">
            {{ $t('employeeDetails.lastName') }}
          </v-col>
          <v-col cols="2" />
        </v-row>
      </div>

      <div
        v-if="value && !loading && value.length > 0"
        class="overview-body table-body"
      >
        <br class="d-none d-sm-block" />
        <template v-for="employee in visibleUsers">
          <target-employee-item
            :value="employee"
            :key="`target_employee_table_item_${employee.id}`"
          ></target-employee-item>
        </template>
      </div>
      <div v-else-if="loading" class="mt-4">
        <v-row>
          <v-col cols="12">
            <v-progress-linear indeterminate></v-progress-linear>
          </v-col>
        </v-row>
      </div>
      <div v-else class="mt-4">
        <v-row>
          <v-col cols="12">
            <v-alert text type="info">
              {{ $t('noEmployeesAvailable') }}
            </v-alert>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-row v-show="numPages > 1" class="mt-4">
      <v-col cols="12">
        <div class="text-center">
          <v-pagination
            v-model="pageIndex"
            :length="numPages"
            :total-visible="7"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import targetEmployeeItem from '@/components/feedbacks/targets/target-employee-item.vue';

export default {
  props: {
    value: {
      type: Array,
      required: false,
      default: () => []
    },

    loading: {
      type: Boolean,
      required: false,
      default: () => false
    },

    itemsPerPage: {
      type: Number,
      required: false,
      default: 15
    }
  },

  data: () => ({
    pageIndex: 1,
    keyword: null
  }),

  computed: {
    filteredItems() {
      let items = [...this.value];

      if (this.keyword && this.keyword.trim() !== '') {
        var kw = this.keyword.trim().toLowerCase();
        items = items.filter(
          (x) =>
            x.firstname.toLowerCase().includes(kw) ||
            x.lastname.toLowerCase().includes(kw)
        );
      }

      return items;
    },

    numPages() {
      return Math.ceil(this.filteredItems.length / this.itemsPerPage);
    },

    visibleUsers() {
      const startItem = (this.pageIndex - 1) * this.itemsPerPage;
      const endItem = Math.min(
        startItem + this.itemsPerPage,
        this.filteredItems.length
      );

      return this.filteredItems.slice(startItem, endItem);
    }
  },

  components: {
    targetEmployeeItem
  }
};
</script>

<style lang="scss" scoped>
.overview-body .row .expandable:hover {
  cursor: pointer;
}

.overview-body .row.table-row-divider:hover {
  background: none;
}

.table {
  margin-left: -16px;
  margin-right: -16px;
}

.table .row.table-row-divider {
  margin-top: 0;
}

.table .row.table-row-divider > div {
  padding-left: 0;
  padding-right: 0;
  padding: 0;
}

.expanded-content {
  background-color: var(--element-background-secondary);

  .row {
    align-items: center;
  }

  .row:after {
    display: block;
    content: ' ';
    width: 100%;
    margin-left: 1em;
    margin-right: 1em;
    border-bottom: 2px dotted rgba(0, 0, 0, 0.1);
  }

  .row:last-of-type:after {
    border-bottom: none;
  }
}
</style>
