<template>
  <div class="mx-n4">
    <br class="d-none d-sm-block" />
    <v-row>
      <v-col cols="12">
        <div class="targets-table-wrapper">
          <targets-table
            :value="feedbackTargets"
            :readOnly="readOnly"
            :statusReadOnly="readOnly"
            :loading="loading"
            :type="targetTableTypes.COPIED"
            @saveTarget="saveTarget($event)"
          />
        </div>

        <div class="targets-table-wrapper">
          <targets-table
            :value="personalTargets"
            :readOnly="readOnly"
            :statusReadOnly="readOnly"
            :loading="loading"
            :type="targetTableTypes.NEW"
            @saveTarget="saveTarget($event)"
          />

          <v-col v-if="!readOnly" class="text-right">
            <target-item-dialog
              :visible="dialogVisible"
              @close="dialogVisible = false"
              @save="createTarget($event)"
            />
            <v-btn color="primary" @click="dialogVisible = true">
              <v-icon>mdi-plus</v-icon>
              {{ $t('targets.newTargets') }}
            </v-btn>
          </v-col>
        </div>

        <div class="targets-table-wrapper no-border">
          <targets-table
            :value="archivedTargets"
            readOnly
            statusReadOnly
            :loading="loading"
            :type="targetTableTypes.ARCHIVED"
            @saveTarget="saveTarget($event)"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import targetsTable from '@/components/feedbacks/targets/targets-table';
import targetItemDialog from '@/components/forms/questionnaire/form-elements/targets/new-targets/dialog.vue';
import { targetEnums } from '@/enums/targets.js';
import { mapActions } from 'vuex';

export default {
  props: {
    value: {
      type: String,
      required: false,
      default: () => ''
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: () => true
    },
    canComment: {
      type: Boolean,
      required: false,
      default: () => true
    }
  },

  data() {
    return {
      targetStatus: targetEnums.targetStatus,
      targetTableTypes: targetEnums.tableTypes,
      targets: [],
      loading: true,
      dialogVisible: false
    };
  },

  computed: {
    archivedTargets() {
      return this.targets.filter((x) => this.isArchived(x.status));
    },

    personalTargets() {
      return this.targets.filter(
        (x) => !this.isArchived(x.status) && !x.isFromFeedback
      );
    },

    feedbackTargets() {
      return this.targets.filter(
        (x) => !this.isArchived(x.status) && x.isFromFeedback
      );
    }
  },

  methods: {
    ...mapActions({
      getTargets: 'feedbacks/getTargets',
      saveTarget: 'feedbacks/saveTarget'
    }),

    createTarget(targetItem) {
      this.targets.push(targetItem);
      this.saveTarget(targetItem);
    },

    isArchived(status) {
      return (
        status == this.targetStatus.FINISHED ||
        status == this.targetStatus.NOT_RELEVANT ||
        status == this.targetStatus.NOT_ACCOMPLISHED
      );
    }
  },

  async mounted() {
    var response = await this.getTargets(this.value);
    if (response.success) {
      this.targets = response.result;
    }

    this.loading = false;
  },

  components: {
    targetsTable,
    targetItemDialog
  }
};
</script>

<style scoped lang="scss">
.table {
  padding-left: 16px;
  padding-right: 16px;
}

.table strong {
  color: #000;
}

.targets-table-wrapper {
  border-bottom: 16px solid var(--v-background-base);
  padding: 16px 6px;
  margin-left: -12px;
  margin-right: -12px;

  &.no-border {
    border-bottom: none;
    padding-bottom: 0;
  }
}
</style>
